@media (min-width: 992px) {
  #sidebar {
    height: 100vh;
  }
}

body .card {
  border: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    opacity: 1 !important;

    svg, h4 {
      color: rgb(90, 230, 210);
    }

    svg {
      transform: scale(1.5);
    }
  }
}

section:hover {
  .card {
    opacity: 0.5;
  }
}

.nav-link {
  &.active {
    opacity: 1 !important;
  }

  .nav-indicator {
    display: block;
    position: relative;
    content: ' ';
    width: 2rem;
    bottom: -0.75rem;
    height: 1px;
    background-color: var(--bs-secondary);
    transition: width 0.5s;
  }

  &.active .nav-indicator {
    width: 5rem;
    padding-right: 2rem;
    background-color: var(--bs-white);
  }
}

html[data-bs-theme="dark"] {
  --bs-body-bg: #1e042d;
}

body .text-bg-teal {
  font-weight: normal;
  color: rgba(90, 230, 210) ;
  background-color: rgba(50,200,190,.2);
}

svg {
  color: var(--bs-secondary);

  &:hover {
    color: var(--bs-white);
  }
}